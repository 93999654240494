import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class IncidentTypesService {
  static async getAll({ page, search, idHeadquarter, idCustomer }) {
    const { data } = await DataService.get(API.serviceTypes.root, { page, search, idHeadquarter, idCustomer });
    return {
      incidentTypes: data.incidentTypes,
      total: data.total,
      pageSize: data.pageSize,
      page,
    };
  }

  static async getById(incidentTypeId) {
    const { data } = await DataService.get(API.serviceTypes.root + incidentTypeId);
    return {
      incidentType: data.serviceType,
      paymentAmounts: data.paymentAmounts,
    };
  }

  static async createIncidentType(data) {
    const incidentTypeData = {
      idProfile: 4,
      name: data.name,
      assetType: data.assetType,
      idColor: data.idColor,
      reference: data.reference,
      useCustomExecutionTime: data.useCustomExecutionTime,
      lengthData: data.lengthData,
      usersLengthData: data.usersLengthData,
      enableAssets: data.enableAssets,
      enableChat: data.enableChat,
      description: data.description,
      selectSkills: data.selectSkills,
      updateAfterFinish: data.updateAfterFinish,
      statusNoCompleted: data.statusNoCompleted,
      whatsappScheulding: data.whatsappScheulding,
      offerCanceledService: data.offerCanceledService,
      enableMobileForm: data.enableMobileForm,
      specialities: data.specialities,
      review_form_web: data.review_form_web,
      review_form_check: data.review_form_check,
      reviewExtendedFormWeb: data.reviewExtendedFormWeb,
      isCancelAutomatically: data.isCancelAutomatically,
      includeInOpportunitiesReport: data.includeInOpportunitiesReport,
      idSequence: data.idSequence,
      idApp: data.idApp,
      idForm: data.idForm,
      idFormRevi: data.idFormRevi,
      idFormExtendedWeb: data.idFormExtendedWeb,
      timeToCancel: data.timeToCancel,
      headquarters: data.headquarters,
      enablePdf: data.enablePdf || false,
      enableCustomPdf: data.enableCustomPdf,
      enableExcelExport: data.enableExcelExport,
      enableEmails: data.enableEmails || false,
      paymentAmounts: data.paymentAmounts,
    };
    const { data: incidentData } = await DataService.post(API.serviceTypes.root, incidentTypeData);
    return {
      incidentType: incidentData.incidentType,
    };
  }

  static async updateIncidentType(id, data) {
    const incidentTypeData = {
      _id: id,
      name: data.name,
      idColor: data.idColor,
      reference: data.reference,
      assetType: data.assetType,
      timeExecution: data.timeExecution,
      description: data.description,
      enableAssets: data.enableAssets,
      enableChat: data.enableChat,
      selectSkills: data.selectSkills,
      updateAfterFinish: data.updateAfterFinish,
      statusNoCompleted: data.statusNoCompleted,
      whatsappScheulding: data.whatsappScheulding,
      offerCanceledService: data.offerCanceledService,
      enableMobileForm: data.enableMobileForm,
      specialities: data.specialities,
      review_form_web: data.review_form_web,
      review_form_check: data.review_form_check,
      reviewExtendedFormWeb: data.reviewExtendedFormWeb,
      review_extended_form_web: data.review_extended_form_web,
      isCancelAutomatically: data.isCancelAutomatically,
      includeInOpportunitiesReport: data.includeInOpportunitiesReport,
      idSequence: data.idSequence,
      idApp: data.idApp,
      idForm: data.idForm,
      idFormRevi: data.idFormRevi,
      idFormExtendedWeb: data.idFormExtendedWeb,
      timeToCancel: data.timeToCancel,
      isUsingVariables: data.isUsingVariables,
      headquarters: data.headquarters,
      enablePdf: data.enablePdf || false,
      enableCustomPdf: data.enableCustomPdf,
      useCustomExecutionTime: data.useCustomExecutionTime,
      lengthData: data.lengthData,
      usersLengthData: data.usersLengthData,
      enableExcelExport: data.enableExcelExport,
      enableEmails: data.enableEmails || false,
      paymentAmounts: data.paymentAmounts,
    };
    const { data: incidentData } = await DataService.patch(`${API.serviceTypes.root}/${id}`, incidentTypeData);
    return {
      incidentType: incidentData.incidentType,
    };
  }

  static async removeIncidentType(id) {
    const { data } = await DataService.delete(`${API.serviceTypes.root}/${id}`);
    return {
      incidentType: data.incidentType,
    };
  }

  static async getTemplatesSettings(incidentTypeId) {
    const { data } = await DataService.get(
      `${API.serviceTypes.root}/${incidentTypeId}/${API.serviceTypes.pdfTemplatesSettings}/assigned`,
    );
    return {
      templatesSettings: data?.templatesSettings,
    };
  }
}

export { IncidentTypesService };
