import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

const SelfSchedulingLanding = lazy(() => import('../../views/selfSchedulingLanding/SelfSchedulingLanding'));
const PaymentResponseView = lazy(() => import('../../views/paymentResponse/PaymentResponse'));
const AppointmentCheckout = lazy(() => import('../../views/appoinmentCheckout/AppointmentCheckout'));

const SelfScheduling = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:accountId/payment-response`} component={PaymentResponseView} />
      <Route exact path={`${path}/:accountId/checkout/:idService`} component={AppointmentCheckout} />
      <Route exact path={`${path}/:accountId/:professional?`} component={SelfSchedulingLanding} />
      <Redirect to="/" />
    </Switch>
  );
};

export default SelfScheduling;
