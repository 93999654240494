import Styled from 'styled-components';

const FormWrapperPage = Styled.div`
    padding: 10px;
    .ant-input-number  {
        width: 100%;
    }
    .ant-form-item {
        padding: 10px;
    }
    .ant-picker {
        width: 100%;
        min-height: 46px;
        .ant-picker-input {
            padding-left: 12px;
        }
    }
`;

const FormWrapper = Styled(FormWrapperPage)`
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #EFEDED;
    border-radius: 6px;
    min-height: 2px; 
    max-height: 5px; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
`;


const ForWrapperoffScroll = Styled.div`
max-height: 800px;
    overflow-x: auto;
`;

export { FormWrapper, FormWrapperPage, ForWrapperoffScroll };
